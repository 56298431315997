import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// UI Kit
import BadgeStatus from 'ui-kit/bagde/badge-status';
import AlertIcon from 'ui-kit/icons/alert/alert-icon';
import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';
import Spinner from 'ui-kit/spinner/spinner';

import { membershipGetVisitsLinkRoutine } from 'state/membership/membership.routines';

// Types
import { MembershipViewVisitsResponse, MembershipVisitStatus, MembershipVisitUIStatus } from 'types/membership';
import UIStatuses from 'types/ui-statuses';

// Util
import { convertToTitleCase } from 'util/string';

// Styles
import './pending-telemedicines-card.style.scss';

export interface PendingTelemedicineProps {
    patientName: string;
    specialty: string;
    date: string | null;
    status: MembershipVisitStatus;
    uiStatus: MembershipVisitUIStatus;
    showPatientName: boolean;
    epostPatientNum: number;
}

const PendingTelemedicinesCard = ({
    patientName,
    specialty,
    date,
    status,
    uiStatus,
    showPatientName,
    epostPatientNum
}: PendingTelemedicineProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const isClickableCard = uiStatus !== 'CANCELED' && uiStatus !== 'UNKOWN' && uiStatus !== 'IN PROGRESS';
    const showWarning = uiStatus === 'INCOMPLETE' || uiStatus === 'WAITING FOR PATIENT';

    const statusMap: Record<MembershipVisitUIStatus, string> = {
        BOOKED: 'booked',
        CANCELED: 'canceled',
        COMPLETED: 'completed',
        'IN PROGRESS': 'inprogress',
        INCOMPLETE: 'incomplete',
        'REFERRED OUT': 'referred',
        REJECTED: 'rejected',
        'WAITING FOR PATIENT': 'waiting',
        UNKOWN: 'unkown'
    };
    const variantMap: Record<string, UIStatuses> = {
        BOOKED: 'success',
        CANCELED: 'smoke',
        COMPLETED: 'smoke',
        'IN PROGRESS': 'primary',
        INCOMPLETE: 'danger',
        'REFERRED OUT': 'smoke',
        REJECTED: 'warning',
        'WAITING FOR PATIENT': 'primary',
        UNKOWN: 'smoke'
    };

    const handleClick = (epostPatientNum: number) => {
        if (!isClickableCard) return;
        setIsLoading(true);
        dispatch(
            membershipGetVisitsLinkRoutine.trigger({
                epostPatientNumber: epostPatientNum,
                onSuccess: (data: MembershipViewVisitsResponse) => {
                    setIsLoading(false);
                    window.location = data.link as string & Location;
                },
                onFailure: () => {
                    setIsLoading(false);
                }
            })
        );
    };

    if (!date || !(uiStatus in statusMap)) return null;

    return (
        <div className={`pending-telemedicines__appointment status--${statusMap[uiStatus]}`}>
            <Spinner isVisible={isLoading} t={t} />
            <button onClick={() => handleClick(epostPatientNum)}>
                <div className="pending-telemedicines__appointment__heading">
                    <div className="pending-telemedicines__appointment__details">
                        {showWarning && <AlertIcon variant="warning" className="warning-icon" />}
                        <div className="pending-telemedicines__appointment__details__name">
                            {showPatientName && <p className="person-name">{convertToTitleCase(patientName)}</p>}
                            <p className="telemedicine-appointment">{specialty}</p>
                        </div>
                    </div>
                    {isClickableCard && <ChevronIcon direction="right" />}
                </div>
                <div className="pending-telemedicines__appointment__status">
                    <BadgeStatus label={uiStatus} variant={variantMap[uiStatus]} />
                </div>
            </button>
        </div>
    );
};

export default PendingTelemedicinesCard;
