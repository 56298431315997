import { useTranslation } from 'react-i18next';

import CategoryCard from 'components/category-card';

import { HealthCareQuery, HealthCareQueryProps } from './query';

export type CategoriesMembershipProps = {
    handleCardClick: () => void;
};

const CategoriesMembership: React.FC<CategoriesMembershipProps> = ({ handleCardClick }) => {
    const { t } = useTranslation();
    const data: HealthCareQueryProps = HealthCareQuery();
    const healthCareContent = data.allTaxonomyTermHealthCare.nodes;

    return (
        healthCareContent &&
        healthCareContent.map((healthCare, index) => {
            const icon = healthCare.relationships.field_hc_icon.relationships.field_media_svg.localFile.publicURL;
            const image = healthCare.relationships.field_hc_image.relationships.field_media_image.localFile;
            const conditions = healthCare.relationships.field_hc_conditions;

            return (
                <CategoryCard
                    key={`health-care-${index}`}
                    icon={icon}
                    image={image}
                    list={conditions}
                    title={healthCare.name}
                    bodyText={healthCare.description.processed}
                    conditions={t('pages.membership.dashboardCategories.numberOfConditionsTreatment', {
                        conditions: conditions.length
                    })}
                    handleClick={handleCardClick}
                />
            );
        })
    );
};

export default CategoriesMembership;
